.IF3k4a_container {
  padding: 30px 40px;
}

._4Ym-mW_windowOverlay {
  z-index: 100;
  background: #00000080;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

._4Ym-mW_modalWindow {
  background: #fff;
  border-radius: 5px;
  max-height: 80%;
  padding: 20px;
  position: relative;
  overflow-y: auto;
}

._4Ym-mW_modalWindow ._4Ym-mW_mainContainer {
  flex-direction: column;
  width: 500px;
  display: flex;
}

._4Ym-mW_modalWindow ._4Ym-mW_mainContainer ._4Ym-mW_title {
  font-size: 24px;
}

._4Ym-mW_modalWindow ._4Ym-mW_mainContainer ._4Ym-mW_contentBox {
  flex-direction: column;
  margin: 10px 15px;
  display: flex;
}

._4Ym-mW_modalWindow ._4Ym-mW_mainContainer ._4Ym-mW_contentBox ._4Ym-mW_subTitle {
  font-size: 18px;
  font-weight: 500;
}

._4Ym-mW_modalWindow ._4Ym-mW_mainContainer ._4Ym-mW_contentBox ._4Ym-mW_info {
  margin: 10px 0;
  font-size: 14px;
}

._4Ym-mW_modalWindow ._4Ym-mW_mainContainer ._4Ym-mW_contentBox ._4Ym-mW_formContent textarea {
  box-sizing: border-box;
  color: #5a5a5d;
  resize: vertical;
  border: 1px solid #bebebf;
  border-radius: 5px;
  width: 100%;
  margin: 15px 0;
  padding: 12px 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

._4Ym-mW_modalWindow ._4Ym-mW_mainContainer ._4Ym-mW_contentBox ._4Ym-mW_formContent ._4Ym-mW_inputBlock {
  flex-direction: column;
  margin: 0 15px 20px;
  display: flex;
}

._4Ym-mW_modalWindow ._4Ym-mW_mainContainer ._4Ym-mW_contentBox ._4Ym-mW_formContent ._4Ym-mW_inputBlock label {
  color: #000;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 2px;
  font-size: 16px;
  font-weight: 300;
  display: flex;
}

._4Ym-mW_modalWindow ._4Ym-mW_mainContainer ._4Ym-mW_contentBox ._4Ym-mW_formContent ._4Ym-mW_inputBlock input {
  box-sizing: border-box;
  color: #5a5a5d;
  border: 1px solid #bebebf;
  border-radius: 8px;
  width: 100%;
  padding: 12px 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

._4Ym-mW_modalWindow ._4Ym-mW_mainContainer ._4Ym-mW_footer {
  justify-content: center;
  align-items: center;
  display: flex;
}

._4Ym-mW_modalWindow ._4Ym-mW_mainContainer ._4Ym-mW_footer ._4Ym-mW_createBtn {
  color: #fff;
  background-color: #ff5151;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  display: flex;
}

._4Ym-mW_modalWindow ._4Ym-mW_mainContainer ._4Ym-mW_footer ._4Ym-mW_createBtn:hover {
  cursor: pointer;
  background-color: #ff3838;
}

._4Ym-mW_modalWindow ._4Ym-mW_jobPreviewContainer {
  flex-direction: column;
  width: 730px;
  display: flex;
}

._4Ym-mW_modalWindow ._4Ym-mW_jobPreviewContainer ._4Ym-mW_title {
  margin-left: 10px;
  font-size: 24px;
}

._4Ym-mW_modalWindow ._4Ym-mW_jobPreviewContainer ._4Ym-mW_paragraph {
  flex-direction: column;
  margin: 15px 35px 10px;
  display: flex;
}

._4Ym-mW_modalWindow ._4Ym-mW_jobPreviewContainer ._4Ym-mW_paragraph ._4Ym-mW_paragraphTitle {
  font-size: 17px;
  font-weight: 600;
}

._4Ym-mW_modalWindow ._4Ym-mW_jobPreviewContainer ._4Ym-mW_paragraph ._4Ym-mW_text {
  font-size: 14px;
}

._4Ym-mW_modalWindow ._4Ym-mW_jobPreviewContainer ._4Ym-mW_paragraph ul {
  margin: 0;
}

._4Ym-mW_modalWindow ._4Ym-mW_jobPreviewContainer ._4Ym-mW_paragraph ul li {
  font-size: 14px;
}

._4Ym-mW_modalWindow ._4Ym-mW_closeWindowBtn {
  cursor: pointer;
  z-index: 10;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  display: flex;
  position: absolute;
  top: 18px;
  right: 15px;
}

._4Ym-mW_modalWindow ._4Ym-mW_closeWindowBtn img {
  width: 15px;
}

body {
  margin: 0;
  padding: 0;
  font-family: Poppins, sans-serif;
}

._23QPhq_mainContainer {
  flex-direction: row;
  display: flex;
}

._23QPhq_container {
  width: 100%;
  height: 100vh;
  margin-left: 270px;
}

._23QPhq_container ._23QPhq_topBar {
  border-bottom: 1px solid #f1f1f1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 95px;
  margin-top: -8px;
  display: flex;
}

._23QPhq_container ._23QPhq_topBar ._23QPhq_rightBlock {
  margin-right: 15px;
  display: flex;
}

._23QPhq_container ._23QPhq_topBar ._23QPhq_rightBlock ._23QPhq_profileBlock {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  padding: 10px 15px;
  display: flex;
}

._23QPhq_container ._23QPhq_topBar ._23QPhq_rightBlock ._23QPhq_profileBlock ._23QPhq_pName {
  margin: 0 8px 0 12px;
}

._23QPhq_container ._23QPhq_topBar ._23QPhq_rightBlock ._23QPhq_profileBlock:hover {
  cursor: pointer;
  background-color: #f1f5f7;
  border-radius: 5px;
}

._23QPhq_container ._23QPhq_mainPageContainer {
  flex-direction: column;
  align-items: center;
  display: flex;
}

._23QPhq_container ._23QPhq_mainPageContainer ._23QPhq_content {
  flex-direction: column;
  display: flex;
}

._23QPhq_container ._23QPhq_dashboardContentContainer {
  flex-direction: column;
  padding-bottom: 25px;
  display: flex;
}

._23QPhq_container ._23QPhq_dashboardContentContainer ._23QPhq_contentBlock {
  flex-direction: row;
  justify-content: space-between;
  margin: 25px 35px 5px;
  display: flex;
}

._23QPhq_container ._23QPhq_dashboardContentContainer ._23QPhq_contentBlock ._23QPhq_dashboardBlock, ._23QPhq_container ._23QPhq_dashboardContentContainer ._23QPhq_contentBlock ._23QPhq_dashboardBlock ._23QPhq_dashboardCardsContainer {
  flex-direction: column;
  display: flex;
}

._23QPhq_container ._23QPhq_dashboardContentContainer ._23QPhq_contentBlock ._23QPhq_dashboardBlock ._23QPhq_dashboardCardsContainer ._23QPhq_smallCardsContainer {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  display: flex;
}

._23QPhq_container ._23QPhq_dashboardContentContainer ._23QPhq_contentBlock ._23QPhq_dashboardBlock ._23QPhq_dashboardCardsContainer ._23QPhq_smallCardsContainer ._23QPhq_dashboardCard {
  background-color: #ffefe7;
  border-radius: 10px;
  flex-direction: column;
  width: 205px;
  height: 138px;
  margin: 0 10px 10px 0;
  display: flex;
}

._23QPhq_container ._23QPhq_dashboardContentContainer ._23QPhq_contentBlock ._23QPhq_dashboardBlock ._23QPhq_dashboardCardsContainer ._23QPhq_smallCardsContainer ._23QPhq_dashboardCard ._23QPhq_cardTitle {
  margin: 15px 0 0 20px;
  font-size: 18px;
  font-weight: 500;
}

._23QPhq_container ._23QPhq_dashboardContentContainer ._23QPhq_contentBlock ._23QPhq_dashboardBlock ._23QPhq_dashboardCardsContainer ._23QPhq_smallCardsContainer ._23QPhq_dashboardCard ._23QPhq_cardValue {
  margin: 0 0 0 20px;
  font-size: 36px;
  font-weight: 600;
}

._23QPhq_container ._23QPhq_dashboardContentContainer ._23QPhq_contentBlock ._23QPhq_dashboardBlock ._23QPhq_dashboardCardsContainer ._23QPhq_smallCardsContainer ._23QPhq_dashboardCard ._23QPhq_cardSubValue {
  color: #ff5151;
  margin: 0 0 0 20px;
  font-size: 16px;
  font-weight: 300;
}

._23QPhq_container ._23QPhq_dashboardContentContainer ._23QPhq_contentBlock ._23QPhq_dashboardBlock ._23QPhq_dashboardCardsContainer ._23QPhq_smallCardsContainer ._23QPhq_dashboardCard:nth-child(2) {
  background-color: #e8f0fb;
}

._23QPhq_container ._23QPhq_dashboardContentContainer ._23QPhq_contentBlock ._23QPhq_dashboardBlock ._23QPhq_dashboardCardsContainer ._23QPhq_smallCardsContainer ._23QPhq_dashboardCard:nth-child(2) ._23QPhq_cardSubValue {
  color: #3786f1;
  margin: 0 0 0 20px;
  font-size: 16px;
  font-weight: 300;
}

._23QPhq_container ._23QPhq_dashboardContentContainer ._23QPhq_contentBlock ._23QPhq_dashboardBlock ._23QPhq_dashboardCardsContainer ._23QPhq_smallCardsContainer ._23QPhq_dashboardCard:nth-child(3) {
  background-color: #fdebf9;
}

._23QPhq_container ._23QPhq_dashboardContentContainer ._23QPhq_contentBlock ._23QPhq_dashboardBlock ._23QPhq_dashboardCardsContainer ._23QPhq_smallCardsContainer ._23QPhq_dashboardCard:nth-child(3) ._23QPhq_cardSubValue {
  color: #ee61cf;
  margin: 0 0 0 20px;
  font-size: 16px;
  font-weight: 300;
}

._23QPhq_container ._23QPhq_dashboardContentContainer ._23QPhq_contentBlock ._23QPhq_dashboardBlock ._23QPhq_dashboardCardsContainer ._23QPhq_largeCardsContainer {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  display: flex;
}

._23QPhq_container ._23QPhq_dashboardContentContainer ._23QPhq_contentBlock ._23QPhq_dashboardBlock ._23QPhq_dashboardCardsContainer ._23QPhq_largeCardsContainer ._23QPhq_dashboardLargeCard {
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  flex-direction: column;
  width: 310px;
  height: 190px;
  margin: 0 10px 10px 0;
  display: flex;
}

._23QPhq_container ._23QPhq_dashboardContentContainer ._23QPhq_contentBlock ._23QPhq_dashboardBlock ._23QPhq_dashboardCardsContainer ._23QPhq_largeCardsContainer ._23QPhq_dashboardLargeCard ._23QPhq_cardTitle {
  margin: 20px 0 0 20px;
  font-size: 18px;
  font-weight: 500;
}

._23QPhq_container ._23QPhq_dashboardContentContainer ._23QPhq_contentBlock ._23QPhq_dashboardBlock ._23QPhq_dashboardCardsContainer ._23QPhq_largeCardsContainer ._23QPhq_dashboardLargeCard ._23QPhq_cardValueContainer {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

._23QPhq_container ._23QPhq_dashboardContentContainer ._23QPhq_contentBlock ._23QPhq_dashboardBlock ._23QPhq_dashboardCardsContainer ._23QPhq_largeCardsContainer ._23QPhq_dashboardLargeCard ._23QPhq_cardValueContainer ._23QPhq_cardValue {
  margin: 0 0 10px 20px;
  font-size: 48px;
  font-weight: 600;
}

._23QPhq_container ._23QPhq_dashboardContentContainer ._23QPhq_contentBlock ._23QPhq_dashboardBlock ._23QPhq_dashboardCardsContainer ._23QPhq_largeCardsContainer ._23QPhq_dashboardLargeCard ._23QPhq_cardValueContainer ._23QPhq_cardSubTitle {
  color: #686868;
  margin: 0 0 0 20px;
  font-size: 12px;
  font-weight: 500;
}

._23QPhq_container ._23QPhq_dashboardContentContainer ._23QPhq_contentBlock ._23QPhq_dashboardBlock ._23QPhq_dashboardCardsContainer ._23QPhq_largeCardsContainer ._23QPhq_dashboardLargeCard ._23QPhq_cardValueContainer ._23QPhq_cardValueGraph {
  min-width: 125px;
  height: 60px;
  margin-right: 20px;
}

._23QPhq_container ._23QPhq_dashboardContentContainer ._23QPhq_contentBlock ._23QPhq_dashboardBlock ._23QPhq_dashboardCardsContainer ._23QPhq_largeCardsContainer ._23QPhq_dashboardLargeCard ._23QPhq_cardValueContainer ._23QPhq_cardValueGraphBadge {
  color: #303030;
  background-color: #ffefe7;
  border-radius: 5px;
  margin-right: 20px;
  padding: 5px 15px;
  font-size: 12px;
}

._23QPhq_container ._23QPhq_dashboardContentContainer ._23QPhq_contentBlock ._23QPhq_dashboardBlock ._23QPhq_dashboardCardsContainer ._23QPhq_largeCardsContainer ._23QPhq_dashboardLargeCard ._23QPhq_cardSubValue {
  color: #686868;
  margin: 0 0 0 20px;
  font-size: 12px;
  font-weight: 300;
}

._23QPhq_profileMenu {
  z-index: 10;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  flex-direction: column;
  align-items: baseline;
  margin-top: 15px;
  padding: 20px;
  display: flex;
  position: absolute;
  right: 20px;
  box-shadow: 0 10px 80px #0000000a;
}

._23QPhq_profileMenu ._23QPhq_menuItem {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100px;
  padding: 10px 20px;
  display: flex;
}

._23QPhq_profileMenu ._23QPhq_menuItem i {
  margin-right: 10px;
}

._23QPhq_profileMenu ._23QPhq_menuItem:hover {
  cursor: pointer;
  background-color: #c7eee033;
  border-radius: 5px;
}

._68xmRa_sidebar {
  height: 100hv;
  background-color: #eff3f5;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 270px;
  max-width: 270px;
  min-height: 100%;
  padding: 25px 0;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

._68xmRa_sidebar a {
  color: #000;
  text-decoration: none;
}

._68xmRa_sidebar ._68xmRa_createJobBtn {
  color: #fff;
  background-color: #ff5151;
  border-radius: 4px;
  flex-direction: row;
  align-items: center;
  width: 220px;
  height: 50px;
  margin: 15px 0 0 10px;
  display: flex;
}

._68xmRa_sidebar ._68xmRa_createJobBtn i {
  color: #fff;
  margin: 0 25px;
  font-size: 20px;
}

._68xmRa_sidebar ._68xmRa_createJobBtn:hover {
  cursor: pointer;
  background-color: #ff3838;
}

._68xmRa_sidebar ._68xmRa_logo {
  margin-top: 10px;
}

._68xmRa_sidebar ._68xmRa_sep {
  min-width: 200px;
}

._68xmRa_sidebar ._68xmRa_sep hr {
  border-top: .5px solid #f5f5f5;
}

._68xmRa_sidebar ._68xmRa_menuBlock {
  flex-direction: column;
  width: 240px;
  margin: 40px 0 30px;
  display: flex;
}

._68xmRa_sidebar ._68xmRa_menuBlock ._68xmRa_menuTitle {
  color: #686868;
  padding: 0 30px 5px;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 500;
}

._68xmRa_sidebar ._68xmRa_menuBlock ._68xmRa_menuItems ul {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

._68xmRa_sidebar ._68xmRa_menuBlock ._68xmRa_menuItems ul ._68xmRa_menuItem {
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 50px;
  margin: 5px 0;
  font-family: Poppins, sans-serif;
  font-weight: 300;
  display: flex;
}

._68xmRa_sidebar ._68xmRa_menuBlock ._68xmRa_menuItems ul ._68xmRa_menuItem ._68xmRa_iconBox {
  align-items: center;
  width: 30px;
  margin: 0 20px 0 30px;
  display: flex;
}

._68xmRa_sidebar ._68xmRa_menuBlock ._68xmRa_menuItems ul ._68xmRa_menuItem ._68xmRa_iconBox i {
  color: #686868;
  font-size: 20px;
}

._68xmRa_sidebar ._68xmRa_menuBlock ._68xmRa_menuItems ul ._68xmRa_menuItem:hover {
  cursor: pointer;
  background-color: #e2e8eb;
  border-radius: 5px;
}

._68xmRa_activeLink {
  color: #ff3838;
  font-weight: bold;
}

.HUSi3a_container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 60px;
  display: flex;
}

.HUSi3a_container img {
  width: 70px;
  margin-bottom: 5px;
}

.HUSi3a_containerLoading {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px;
  display: flex;
}

.HUSi3a_containerLoading img {
  width: 70px;
  margin-bottom: 5px;
}

.rW8UwG_dashboardBlock {
  flex-direction: column;
  display: flex;
}

.rW8UwG_dashboardBlock .rW8UwG_titleBar {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.rW8UwG_dashboardBlock .rW8UwG_titleBar .rW8UwG_mainTitle {
  font-size: 24px;
  font-weight: 500;
  display: flex;
}

.rW8UwG_dashboardBlock .rW8UwG_titleBar .rW8UwG_dateSelector {
  justify-content: center;
  align-items: center;
  display: flex;
}

.rW8UwG_dashboardBlock .rW8UwG_dashboardCardsContainer {
  flex-direction: column;
  display: flex;
}

.rW8UwG_dashboardBlock .rW8UwG_dashboardCardsContainer img {
  margin-top: 15px;
}

.rW8UwG_dashboardBlock .rW8UwG_dashboardCardsContainer .rW8UwG_smallCardsContainer {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  display: flex;
}

.rW8UwG_dashboardBlock .rW8UwG_dashboardCardsContainer .rW8UwG_smallCardsContainer .rW8UwG_dashboardCard {
  background-color: #ffefe7;
  border-radius: 10px;
  flex-direction: column;
  width: 205px;
  height: 138px;
  margin: 0 10px 10px 0;
  display: flex;
}

.rW8UwG_dashboardBlock .rW8UwG_dashboardCardsContainer .rW8UwG_smallCardsContainer .rW8UwG_dashboardCard .rW8UwG_cardTitle {
  margin: 15px 0 0 20px;
  font-size: 18px;
  font-weight: 500;
}

.rW8UwG_dashboardBlock .rW8UwG_dashboardCardsContainer .rW8UwG_smallCardsContainer .rW8UwG_dashboardCard .rW8UwG_cardValue {
  margin: 0 0 0 20px;
  font-size: 36px;
  font-weight: 600;
}

.rW8UwG_dashboardBlock .rW8UwG_dashboardCardsContainer .rW8UwG_smallCardsContainer .rW8UwG_dashboardCard .rW8UwG_cardSubValue {
  color: #ff5151;
  margin: 0 0 0 20px;
  font-size: 16px;
  font-weight: 300;
}

.rW8UwG_dashboardBlock .rW8UwG_dashboardCardsContainer .rW8UwG_smallCardsContainer .rW8UwG_dashboardCard:nth-child(2) {
  background-color: #e8f0fb;
}

.rW8UwG_dashboardBlock .rW8UwG_dashboardCardsContainer .rW8UwG_smallCardsContainer .rW8UwG_dashboardCard:nth-child(2) .rW8UwG_cardSubValue {
  color: #3786f1;
  margin: 0 0 0 20px;
  font-size: 16px;
  font-weight: 300;
}

.rW8UwG_dashboardBlock .rW8UwG_dashboardCardsContainer .rW8UwG_smallCardsContainer .rW8UwG_dashboardCard:nth-child(3) {
  background-color: #fdebf9;
}

.rW8UwG_dashboardBlock .rW8UwG_dashboardCardsContainer .rW8UwG_smallCardsContainer .rW8UwG_dashboardCard:nth-child(3) .rW8UwG_cardSubValue {
  color: #ee61cf;
  margin: 0 0 0 20px;
  font-size: 16px;
  font-weight: 300;
}

.rW8UwG_dashboardBlock .rW8UwG_dashboardCardsContainer .rW8UwG_largeCardsContainer {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  display: flex;
}

.rW8UwG_dashboardBlock .rW8UwG_dashboardCardsContainer .rW8UwG_largeCardsContainer .rW8UwG_dashboardLargeCard {
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  flex-direction: column;
  width: 310px;
  height: 190px;
  margin: 0 10px 10px 0;
  display: flex;
}

.rW8UwG_dashboardBlock .rW8UwG_dashboardCardsContainer .rW8UwG_largeCardsContainer .rW8UwG_dashboardLargeCard .rW8UwG_cardTitle {
  margin: 20px 0 0 20px;
  font-size: 18px;
  font-weight: 500;
}

.rW8UwG_dashboardBlock .rW8UwG_dashboardCardsContainer .rW8UwG_largeCardsContainer .rW8UwG_dashboardLargeCard .rW8UwG_cardValueContainer {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.rW8UwG_dashboardBlock .rW8UwG_dashboardCardsContainer .rW8UwG_largeCardsContainer .rW8UwG_dashboardLargeCard .rW8UwG_cardValueContainer .rW8UwG_cardValue {
  margin: 0 0 10px 20px;
  font-size: 48px;
  font-weight: 600;
}

.rW8UwG_dashboardBlock .rW8UwG_dashboardCardsContainer .rW8UwG_largeCardsContainer .rW8UwG_dashboardLargeCard .rW8UwG_cardValueContainer .rW8UwG_cardSubTitle {
  color: #686868;
  margin: 0 0 0 20px;
  font-size: 12px;
  font-weight: 500;
}

.rW8UwG_dashboardBlock .rW8UwG_dashboardCardsContainer .rW8UwG_largeCardsContainer .rW8UwG_dashboardLargeCard .rW8UwG_cardValueContainer .rW8UwG_cardValueGraph {
  min-width: 125px;
  height: 60px;
  margin-right: 20px;
}

.rW8UwG_dashboardBlock .rW8UwG_dashboardCardsContainer .rW8UwG_largeCardsContainer .rW8UwG_dashboardLargeCard .rW8UwG_cardValueContainer .rW8UwG_cardValueGraphBadge {
  color: #303030;
  background-color: #ffefe7;
  border-radius: 5px;
  margin-right: 20px;
  padding: 5px 15px;
  font-size: 12px;
}

.rW8UwG_dashboardBlock .rW8UwG_dashboardCardsContainer .rW8UwG_largeCardsContainer .rW8UwG_dashboardLargeCard .rW8UwG_cardSubValue {
  color: #686868;
  margin: 0 0 0 20px;
  font-size: 12px;
  font-weight: 300;
}

.rW8UwG_datePicker {
  color: #686868;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  width: 130px;
  margin-right: 10px;
  padding: 5px 10px;
  font-size: 11px;
}

.CTcaqq_activityBlock {
  background-color: #161e54;
  border-radius: 10px;
  flex-direction: column;
  min-width: 425px;
  max-width: 425px;
  margin-left: 20px;
  display: flex;
}

.CTcaqq_activityBlock .CTcaqq_titleBar {
  color: #fff;
  background-color: #1b204a;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 15px 25px;
  font-size: 18px;
  font-weight: 500;
}

.CTcaqq_activityBlock .CTcaqq_acivityItems {
  margin: 5px 10px 15px;
}

.CTcaqq_activityBlock .CTcaqq_acivityItems .CTcaqq_acivityItem {
  flex-direction: column;
  padding: 10px 15px;
  display: flex;
}

.CTcaqq_activityBlock .CTcaqq_acivityItems .CTcaqq_acivityItem .CTcaqq_date {
  color: #fff;
  font-size: 10px;
  font-weight: 100;
}

.CTcaqq_activityBlock .CTcaqq_acivityItems .CTcaqq_acivityItem .CTcaqq_title {
  color: #fff;
  margin-top: 4px;
  font-size: 18px;
  font-weight: 400;
}

.CTcaqq_activityBlock .CTcaqq_acivityItems .CTcaqq_acivityItem .CTcaqq_message {
  color: #fff;
  margin: 0 0 5px;
  font-size: 13px;
  font-weight: 100;
}

.CTcaqq_activityBlock .CTcaqq_acivityItems .CTcaqq_acivityItem:hover {
  cursor: pointer;
  background-color: #2f3882;
  border-radius: 5px;
}

.CTcaqq_activityBlock .CTcaqq_footer {
  justify-content: space-around;
  align-items: center;
  margin: 30px 20px;
  display: flex;
}

.CTcaqq_activityBlock .CTcaqq_footer .CTcaqq_info {
  color: #fff;
  font-size: 14px;
  font-weight: 200;
}

.CTcaqq_activityBlock .CTcaqq_footer .CTcaqq_seeActivityBtn {
  color: #fff;
  background-color: #ff5151;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 35px;
  font-size: 14px;
  display: flex;
}

.CTcaqq_activityBlock .CTcaqq_footer .CTcaqq_seeActivityBtn:hover {
  cursor: pointer;
  background-color: #ff6262;
}

a {
  text-decoration: none;
}

.zTD7tG_funnelBlock {
  flex-direction: column;
  width: 635px;
  margin-top: 25px;
  display: flex;
}

.zTD7tG_funnelBlock .zTD7tG_titleBar {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.zTD7tG_funnelBlock .zTD7tG_titleBar .zTD7tG_mainTitle {
  font-size: 24px;
  font-weight: 500;
  display: flex;
}

.zTD7tG_funnelBlock .zTD7tG_titleBar .zTD7tG_dateSelector {
  justify-content: center;
  align-items: center;
  display: flex;
}

.zTD7tG_funnelBlock .zTD7tG_funnelTableContainer {
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  flex-direction: column;
  margin-top: 10px;
  display: flex;
}

.zTD7tG_funnelBlock .zTD7tG_funnelTableContainer .zTD7tG_header {
  border-bottom: 1px solid #e0e0e0;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px 30px 10px;
  padding-bottom: 10px;
  font-weight: 600;
  display: flex;
}

.zTD7tG_funnelBlock .zTD7tG_funnelTableContainer .zTD7tG_body {
  flex-direction: column;
  margin: 0 30px 20px;
  font-size: 14px;
  display: flex;
}

.zTD7tG_funnelBlock .zTD7tG_funnelTableContainer .zTD7tG_body .zTD7tG_row {
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  display: flex;
}

.zTD7tG_funnelBlock .zTD7tG_funnelTableContainer .zTD7tG_col1 {
  min-width: 220px;
}

.zTD7tG_funnelBlock .zTD7tG_funnelTableContainer .zTD7tG_col2, .zTD7tG_funnelBlock .zTD7tG_funnelTableContainer .zTD7tG_col3, .zTD7tG_funnelBlock .zTD7tG_funnelTableContainer .zTD7tG_col4 {
  text-align: center;
  min-width: 110px;
}

._25HcyW_recentJobsBlock {
  border: 1px solid #3786f1;
  border-radius: 10px;
  flex-direction: column;
  min-width: 405px;
  max-width: 405px;
  margin: 25px 25px 0 0;
  display: flex;
}

._25HcyW_recentJobsBlock ._25HcyW_header {
  color: #fff;
  background-color: #3786f1;
  border: 1px solid #3786f1;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
  margin-bottom: 3px;
  padding: 12px 0;
  font-size: 18px;
  font-weight: 500;
}

._25HcyW_recentJobsBlock ._25HcyW_header ._25HcyW_title {
  margin-left: 30px;
}

._25HcyW_recentJobsBlock ._25HcyW_item {
  flex-direction: column;
  margin-bottom: 3px;
  padding: 15px 30px;
  display: flex;
}

._25HcyW_recentJobsBlock ._25HcyW_item a {
  color: #000;
  text-decoration: none;
}

._25HcyW_recentJobsBlock ._25HcyW_item ._25HcyW_title {
  font-size: 18px;
  font-weight: 400;
}

._25HcyW_recentJobsBlock ._25HcyW_item ._25HcyW_subTitle {
  font-size: 14px;
  font-weight: 200;
}

._25HcyW_recentJobsBlock ._25HcyW_item ._25HcyW_dateTime {
  font-size: 10px;
  font-weight: 200;
}

._25HcyW_recentJobsBlock ._25HcyW_item:hover {
  cursor: pointer;
  background-color: #e8f0fb;
}

.ZDLqUa_recruitmentContentContainer {
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 25px;
  display: flex;
}

.ZDLqUa_recruitmentContentContainer .ZDLqUa_contentBlock {
  flex-direction: column;
  margin: 25px 25px 5px 35px;
  display: flex;
}

.BMzjmW_employeeTableBlock {
  flex-direction: column;
  margin-top: 25px;
  display: flex;
}

.BMzjmW_employeeTableBlock .BMzjmW_titleBar {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.BMzjmW_employeeTableBlock .BMzjmW_titleBar .BMzjmW_mainTitle {
  font-size: 24px;
  font-weight: 500;
  display: flex;
}

.BMzjmW_employeeTableBlock .BMzjmW_titleBar .BMzjmW_dateSelector {
  justify-content: center;
  align-items: center;
  display: flex;
}

.BMzjmW_employeeTableBlock .BMzjmW_employeeTableContainer {
  margin-top: 10px;
}

.kEhxSa_employeesContentContainer {
  flex-direction: column;
  padding-bottom: 25px;
  display: flex;
}

.kEhxSa_employeesContentContainer .kEhxSa_contentBlock {
  flex-direction: row;
  justify-content: space-between;
  margin: 25px 35px 5px;
  display: flex;
}

.t0we-q_jobContentContainer {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 25px;
  display: flex;
}

.t0we-q_jobContentContainer .t0we-q_contentBlock {
  flex-direction: row;
  justify-content: space-between;
  margin: 25px 35px 5px;
  display: flex;
}

.t0we-q_jobContentContainer .t0we-q_contentBlock .t0we-q_mainContainer {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 550px;
  display: flex;
}

.t0we-q_jobContentContainer .t0we-q_contentBlock .t0we-q_mainContainer .t0we-q_title {
  font-size: 38px;
  font-weight: 400;
}

.t0we-q_jobContentContainer .t0we-q_contentBlock .t0we-q_mainContainer .t0we-q_createBtn {
  color: #fff;
  background-color: #ff5151;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  margin-top: -20px;
  margin-bottom: 30px;
  padding: 10px 20px;
  display: flex;
}

.t0we-q_jobContentContainer .t0we-q_contentBlock .t0we-q_mainContainer .t0we-q_createBtn:hover {
  cursor: pointer;
  background-color: #ff3838;
}

.t0we-q_jobContentContainer .t0we-q_contentBlock .t0we-q_mainContainer .t0we-q_formContent {
  width: 90%;
  margin: 10px 0;
  padding: 30px 0;
}

.t0we-q_jobContentContainer .t0we-q_contentBlock .t0we-q_mainContainer .t0we-q_formContent .t0we-q_inputBlock {
  flex-direction: column;
  margin: 30px 15px 80px;
  display: flex;
}

.t0we-q_jobContentContainer .t0we-q_contentBlock .t0we-q_mainContainer .t0we-q_formContent .t0we-q_inputBlock label {
  color: #000;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 8px;
  font-size: 18px;
  font-weight: 300;
  display: flex;
}

.t0we-q_jobContentContainer .t0we-q_contentBlock .t0we-q_mainContainer .t0we-q_formContent .t0we-q_inputBlock .t0we-q_inputArea {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.t0we-q_jobContentContainer .t0we-q_contentBlock .t0we-q_mainContainer .t0we-q_formContent .t0we-q_inputBlock .t0we-q_inputArea input {
  box-sizing: border-box;
  color: #5a5a5d;
  border: 1px solid #bebebf;
  border-radius: 5px;
  width: 100%;
  padding: 12px 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.t0we-q_jobContentContainer .t0we-q_contentBlock .t0we-q_mainContainer .t0we-q_formContent .t0we-q_inputBlock .t0we-q_inputArea .t0we-q_addBtn {
  color: #25262a;
  border: 1px solid #bebebf;
  border-radius: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 40px;
  margin-left: 15px;
  font-size: 14px;
  display: flex;
}

.t0we-q_jobContentContainer .t0we-q_contentBlock .t0we-q_mainContainer .t0we-q_formContent .t0we-q_inputBlock .t0we-q_inputArea .t0we-q_addBtn:hover {
  cursor: pointer;
}

.t0we-q_jobContentContainer .t0we-q_contentBlock .t0we-q_mainContainer .t0we-q_formContent .t0we-q_inputBlock .t0we-q_listArea .t0we-q_listContainer {
  color: #25262a;
  margin: 25px 10px 0;
  padding: 0;
  font-size: 14px;
}

.t0we-q_jobContentContainer .t0we-q_contentBlock .t0we-q_mainContainer .t0we-q_formContent .t0we-q_inputBlock .t0we-q_listArea .t0we-q_listContainer .t0we-q_listItemBlock {
  border-radius: 5px;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2px;
  padding: 10px 15px;
  display: flex;
}

.t0we-q_jobContentContainer .t0we-q_contentBlock .t0we-q_mainContainer .t0we-q_formContent .t0we-q_inputBlock .t0we-q_listArea .t0we-q_listContainer .t0we-q_listItemBlock .t0we-q_listItem {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.t0we-q_jobContentContainer .t0we-q_contentBlock .t0we-q_mainContainer .t0we-q_formContent .t0we-q_inputBlock .t0we-q_listArea .t0we-q_listContainer .t0we-q_listItemBlock .t0we-q_listItem i {
  margin: -1px 20px 0 0;
}

.t0we-q_jobContentContainer .t0we-q_contentBlock .t0we-q_mainContainer .t0we-q_formContent .t0we-q_inputBlock .t0we-q_listArea .t0we-q_listContainer .t0we-q_listItemBlock .t0we-q_itemEdit {
  flex-direction: column;
  align-items: center;
  min-width: 20px;
  margin-left: 5px;
  display: flex;
}

.t0we-q_jobContentContainer .t0we-q_contentBlock .t0we-q_mainContainer .t0we-q_formContent .t0we-q_inputBlock .t0we-q_listArea .t0we-q_listContainer .t0we-q_listItemBlock:hover {
  cursor: pointer;
  background-color: #e8f0fb;
}

.t0we-q_jobContentContainer .t0we-q_contentBlock .t0we-q_mainContainer .t0we-q_formContent .t0we-q_inputBlock .t0we-q_listArea .t0we-q_listContainer .t0we-q_listItemBlockFocused {
  border: 1px solid #e8f0fb;
  border-radius: 5px;
  flex-direction: column;
  margin: 10px 0;
  padding: 10px 15px;
  display: flex;
}

.t0we-q_jobContentContainer .t0we-q_contentBlock .t0we-q_mainContainer .t0we-q_formContent .t0we-q_inputBlock .t0we-q_listArea .t0we-q_listContainer .t0we-q_listItemBlockFocused .t0we-q_textEditArea {
  box-sizing: border-box;
  color: #5a5a5d;
  resize: none;
  border: 1px solid #bebebf;
  border-radius: 5px;
  width: 90%;
  margin: 20px;
  padding: 12px 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.t0we-q_jobContentContainer .t0we-q_contentBlock .t0we-q_mainContainer .t0we-q_formContent .t0we-q_inputBlock .t0we-q_listArea .t0we-q_listContainer .t0we-q_listItemBlockFocused .t0we-q_buttonsContainer {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 20px 15px;
  font-size: 12px;
  display: flex;
}

.t0we-q_jobContentContainer .t0we-q_contentBlock .t0we-q_mainContainer .t0we-q_formContent .t0we-q_inputBlock .t0we-q_listArea .t0we-q_listContainer .t0we-q_listItemBlockFocused .t0we-q_buttonsContainer .t0we-q_right {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.t0we-q_jobContentContainer .t0we-q_contentBlock .t0we-q_mainContainer .t0we-q_formContent .t0we-q_inputBlock .t0we-q_listArea .t0we-q_listContainer .t0we-q_listItemBlockFocused .t0we-q_buttonsContainer .t0we-q_saveBtn {
  cursor: pointer;
  border: 1px solid #000;
  border-radius: 5px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  padding: 5px 10px;
  display: flex;
}

.t0we-q_jobContentContainer .t0we-q_contentBlock .t0we-q_mainContainer .t0we-q_formContent .t0we-q_inputBlock .t0we-q_listArea .t0we-q_listContainer .t0we-q_listItemBlockFocused .t0we-q_buttonsContainer .t0we-q_paraphraseBtn {
  cursor: pointer;
  background-color: #e8f0fb;
  border-radius: 5px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  display: flex;
}

.t0we-q_jobContentContainer .t0we-q_contentBlock .t0we-q_mainContainer .t0we-q_formContent .t0we-q_inputBlock .t0we-q_listArea .t0we-q_listContainer .t0we-q_listItemBlockFocused .t0we-q_buttonsContainer .t0we-q_removeBtn {
  cursor: pointer;
  text-decoration: underline;
}

.t0we-q_jobContentContainer .t0we-q_contentBlock .t0we-q_mainContainer .t0we-q_formContent .t0we-q_inputBlock .t0we-q_listArea .t0we-q_listContainer .t0we-q_listItemBlockFocused .t0we-q_buttonsContainer .t0we-q_saveBtn:hover {
  color: #fff;
  background-color: #25262a;
}

.t0we-q_jobContentContainer .t0we-q_contentBlock .t0we-q_mainContainer .t0we-q_formContent .t0we-q_inputBlock .t0we-q_listArea .t0we-q_listContainer .t0we-q_listItemBlockFocused .t0we-q_buttonsContainer .t0we-q_removeBtn:hover {
  color: red;
}

.t0we-q_jobContentContainer .t0we-q_contentBlock .t0we-q_mainContainer .t0we-q_formContent .t0we-q_inputBlock textarea {
  box-sizing: border-box;
  color: #5a5a5d;
  resize: vertical;
  border: 1px solid #bebebf;
  border-radius: 5px;
  width: 100%;
  padding: 12px 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.t0we-q_jobContentContainer .t0we-q_contentBlock .t0we-q_mainContainer .t0we-q_formContent .t0we-q_inputBlock .t0we-q_buttonsArea {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0 0;
  display: flex;
}

.t0we-q_jobContentContainer .t0we-q_contentBlock .t0we-q_mainContainer .t0we-q_formContent .t0we-q_inputBlock .t0we-q_buttonsArea .t0we-q_underlineBtn {
  color: #25262a;
  border: 1px solid #25262a;
  border-radius: 5px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 45px;
  display: flex;
}

.t0we-q_jobContentContainer .t0we-q_contentBlock .t0we-q_mainContainer .t0we-q_formContent .t0we-q_inputBlock .t0we-q_buttonsArea .t0we-q_underlineBtn i, .t0we-q_jobContentContainer .t0we-q_contentBlock .t0we-q_mainContainer .t0we-q_formContent .t0we-q_inputBlock .t0we-q_buttonsArea .t0we-q_underlineBtn .t0we-q_icon {
  margin-right: 5px;
}

.t0we-q_jobContentContainer .t0we-q_contentBlock .t0we-q_mainContainer .t0we-q_formContent .t0we-q_inputBlock .t0we-q_buttonsArea .t0we-q_underlineBtn:hover {
  cursor: pointer;
  border: 1px solid #25262a;
  border-radius: 5px;
}

.t0we-q_jobContentContainer .t0we-q_contentBlock .t0we-q_mainContainer .t0we-q_formContent .t0we-q_inputBlock .t0we-q_buttonsArea .t0we-q_mainBtn {
  color: #25262a;
  border-radius: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 45px;
  text-decoration: underline;
  display: flex;
}

.t0we-q_jobContentContainer .t0we-q_contentBlock .t0we-q_mainContainer .t0we-q_formContent .t0we-q_inputBlock .t0we-q_buttonsArea .t0we-q_mainBtn:hover {
  cursor: pointer;
}

.EJsd9W_jobContentContainer {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 25px;
  display: flex;
}

.EJsd9W_jobContentContainer .EJsd9W_contentBlock {
  flex-direction: row;
  justify-content: space-between;
  margin: 25px 35px 5px;
  display: flex;
}

.EJsd9W_jobContentContainer .EJsd9W_contentBlock .EJsd9W_mainContainer {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 600px;
  display: flex;
}

.EJsd9W_jobContentContainer .EJsd9W_contentBlock .EJsd9W_mainContainer .EJsd9W_title {
  font-size: 24px;
}

.EJsd9W_jobContentContainer .EJsd9W_contentBlock .EJsd9W_mainContainer .EJsd9W_createBtn {
  color: #fff;
  background-color: #ff5151;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  display: flex;
}

.EJsd9W_jobContentContainer .EJsd9W_contentBlock .EJsd9W_mainContainer .EJsd9W_createBtn:hover {
  cursor: pointer;
  background-color: #ff3838;
}

.EJsd9W_jobContentContainer .EJsd9W_contentBlock .EJsd9W_mainContainer .EJsd9W_formContent {
  width: 90%;
  margin: 10px 0;
  padding: 30px 0;
}

.EJsd9W_jobContentContainer .EJsd9W_contentBlock .EJsd9W_mainContainer .EJsd9W_formContent .EJsd9W_inputBlock {
  flex-direction: column;
  margin: 0 15px 20px;
  display: flex;
}

.EJsd9W_jobContentContainer .EJsd9W_contentBlock .EJsd9W_mainContainer .EJsd9W_formContent .EJsd9W_inputBlock label {
  color: #000;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 2px;
  font-size: 16px;
  font-weight: 300;
  display: flex;
}

.EJsd9W_jobContentContainer .EJsd9W_contentBlock .EJsd9W_mainContainer .EJsd9W_formContent .EJsd9W_inputBlock input {
  box-sizing: border-box;
  color: #5a5a5d;
  border: 1px solid #bebebf;
  border-radius: 8px;
  width: 100%;
  padding: 12px 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.EJsd9W_jobContentContainer .EJsd9W_contentBlock .EJsd9W_jobPreviewContainer {
  flex-direction: column;
  display: flex;
}

.EJsd9W_jobContentContainer .EJsd9W_contentBlock .EJsd9W_jobPreviewContainer .EJsd9W_title {
  font-size: 24px;
}

.EJsd9W_jobContentContainer .EJsd9W_contentBlock .EJsd9W_jobPreviewContainer .EJsd9W_paragraph {
  flex-direction: column;
  margin: 0 35px 30px;
  display: flex;
}

.EJsd9W_jobContentContainer .EJsd9W_contentBlock .EJsd9W_jobPreviewContainer .EJsd9W_paragraph .EJsd9W_paragraphTitle {
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: 600;
}

.EJsd9W_jobContentContainer .EJsd9W_contentBlock .EJsd9W_jobPreviewContainer .EJsd9W_paragraph .EJsd9W_text {
  font-size: 14px;
}

.EJsd9W_jobContentContainer .EJsd9W_contentBlock .EJsd9W_jobPreviewContainer .EJsd9W_paragraph ul {
  margin: 0;
}

.EJsd9W_jobContentContainer .EJsd9W_contentBlock .EJsd9W_jobPreviewContainer .EJsd9W_paragraph ul li {
  font-size: 14px;
}

.EJsd9W_jobContentContainer .EJsd9W_contentBlock .EJsd9W_jobPreviewContainer .EJsd9W_btnArea {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 250px;
  margin-bottom: 50px;
  display: flex;
}

.EJsd9W_jobContentContainer .EJsd9W_contentBlock .EJsd9W_jobPreviewContainer .EJsd9W_btnArea .EJsd9W_btnEdit {
  color: #fff;
  cursor: pointer;
  background-color: #ff5151;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding: 10px 40px;
  display: flex;
}

.EJsd9W_jobContentContainer .EJsd9W_contentBlock .EJsd9W_jobPreviewContainer .EJsd9W_btnArea .EJsd9W_btnCopy {
  cursor: pointer;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  text-decoration: underline;
  display: flex;
}

.Ij9SVa_settingsPageContainer {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 25px;
  display: flex;
}

.Ij9SVa_settingsPageContainer .Ij9SVa_contentBlock {
  flex-direction: row;
  justify-content: space-between;
  margin: 25px 35px 5px;
  display: flex;
}

.Ij9SVa_settingsPageContainer .Ij9SVa_contentBlock .Ij9SVa_mainContainer {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 550px;
  display: flex;
}

.Ij9SVa_settingsPageContainer .Ij9SVa_contentBlock .Ij9SVa_mainContainer .Ij9SVa_title {
  font-size: 38px;
  font-weight: 400;
}

.Ij9SVa_settingsPageContainer .Ij9SVa_contentBlock .Ij9SVa_mainContainer .Ij9SVa_createBtn {
  color: #fff;
  background-color: #ff5151;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  margin-top: -20px;
  margin-bottom: 30px;
  padding: 10px 20px;
  display: flex;
}

.Ij9SVa_settingsPageContainer .Ij9SVa_contentBlock .Ij9SVa_mainContainer .Ij9SVa_createBtn:hover {
  cursor: pointer;
  background-color: #ff3838;
}

.Ij9SVa_settingsPageContainer .Ij9SVa_contentBlock .Ij9SVa_mainContainer .Ij9SVa_formContent {
  width: 90%;
  margin: 10px 0;
  padding: 30px 0;
}

.Ij9SVa_settingsPageContainer .Ij9SVa_contentBlock .Ij9SVa_mainContainer .Ij9SVa_formContent .Ij9SVa_inputBlock {
  flex-direction: column;
  margin: 30px 15px 80px;
  display: flex;
}

.Ij9SVa_settingsPageContainer .Ij9SVa_contentBlock .Ij9SVa_mainContainer .Ij9SVa_formContent .Ij9SVa_inputBlock label {
  color: #000;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 8px;
  font-size: 18px;
  font-weight: 300;
  display: flex;
}

.Ij9SVa_settingsPageContainer .Ij9SVa_contentBlock .Ij9SVa_mainContainer .Ij9SVa_formContent .Ij9SVa_inputBlock .Ij9SVa_inputArea {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.Ij9SVa_settingsPageContainer .Ij9SVa_contentBlock .Ij9SVa_mainContainer .Ij9SVa_formContent .Ij9SVa_inputBlock .Ij9SVa_inputArea input {
  box-sizing: border-box;
  color: #5a5a5d;
  border: 1px solid #bebebf;
  border-radius: 5px;
  width: 100%;
  padding: 12px 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.Ij9SVa_settingsPageContainer .Ij9SVa_contentBlock .Ij9SVa_mainContainer .Ij9SVa_formContent .Ij9SVa_inputBlock .Ij9SVa_inputArea .Ij9SVa_addBtn {
  color: #25262a;
  border: 1px solid #bebebf;
  border-radius: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 40px;
  margin-left: 15px;
  font-size: 14px;
  display: flex;
}

.Ij9SVa_settingsPageContainer .Ij9SVa_contentBlock .Ij9SVa_mainContainer .Ij9SVa_formContent .Ij9SVa_inputBlock .Ij9SVa_inputArea .Ij9SVa_addBtn:hover {
  cursor: pointer;
}

.Ij9SVa_settingsPageContainer .Ij9SVa_contentBlock .Ij9SVa_mainContainer .Ij9SVa_formContent .Ij9SVa_inputBlock .Ij9SVa_listArea .Ij9SVa_listContainer {
  color: #25262a;
  margin: 25px 10px 0;
  padding: 0;
  font-size: 14px;
}

.Ij9SVa_settingsPageContainer .Ij9SVa_contentBlock .Ij9SVa_mainContainer .Ij9SVa_formContent .Ij9SVa_inputBlock .Ij9SVa_listArea .Ij9SVa_listContainer .Ij9SVa_listItemBlock {
  border-radius: 5px;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2px;
  padding: 10px 15px;
  display: flex;
}

.Ij9SVa_settingsPageContainer .Ij9SVa_contentBlock .Ij9SVa_mainContainer .Ij9SVa_formContent .Ij9SVa_inputBlock .Ij9SVa_listArea .Ij9SVa_listContainer .Ij9SVa_listItemBlock .Ij9SVa_listItem {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.Ij9SVa_settingsPageContainer .Ij9SVa_contentBlock .Ij9SVa_mainContainer .Ij9SVa_formContent .Ij9SVa_inputBlock .Ij9SVa_listArea .Ij9SVa_listContainer .Ij9SVa_listItemBlock .Ij9SVa_listItem i {
  margin: -1px 20px 0 0;
}

.Ij9SVa_settingsPageContainer .Ij9SVa_contentBlock .Ij9SVa_mainContainer .Ij9SVa_formContent .Ij9SVa_inputBlock .Ij9SVa_listArea .Ij9SVa_listContainer .Ij9SVa_listItemBlock .Ij9SVa_itemEdit {
  flex-direction: column;
  align-items: center;
  min-width: 20px;
  margin-left: 5px;
  display: flex;
}

.Ij9SVa_settingsPageContainer .Ij9SVa_contentBlock .Ij9SVa_mainContainer .Ij9SVa_formContent .Ij9SVa_inputBlock .Ij9SVa_listArea .Ij9SVa_listContainer .Ij9SVa_listItemBlock:hover {
  cursor: pointer;
  background-color: #e8f0fb;
}

.Ij9SVa_settingsPageContainer .Ij9SVa_contentBlock .Ij9SVa_mainContainer .Ij9SVa_formContent .Ij9SVa_inputBlock .Ij9SVa_listArea .Ij9SVa_listContainer .Ij9SVa_listItemBlockFocused {
  border: 1px solid #e8f0fb;
  border-radius: 5px;
  flex-direction: column;
  margin: 10px 0;
  padding: 10px 15px;
  display: flex;
}

.Ij9SVa_settingsPageContainer .Ij9SVa_contentBlock .Ij9SVa_mainContainer .Ij9SVa_formContent .Ij9SVa_inputBlock .Ij9SVa_listArea .Ij9SVa_listContainer .Ij9SVa_listItemBlockFocused .Ij9SVa_textEditArea {
  box-sizing: border-box;
  color: #5a5a5d;
  resize: none;
  border: 1px solid #bebebf;
  border-radius: 5px;
  width: 90%;
  margin: 20px;
  padding: 12px 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.Ij9SVa_settingsPageContainer .Ij9SVa_contentBlock .Ij9SVa_mainContainer .Ij9SVa_formContent .Ij9SVa_inputBlock .Ij9SVa_listArea .Ij9SVa_listContainer .Ij9SVa_listItemBlockFocused .Ij9SVa_buttonsContainer {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 20px 15px;
  font-size: 12px;
  display: flex;
}

.Ij9SVa_settingsPageContainer .Ij9SVa_contentBlock .Ij9SVa_mainContainer .Ij9SVa_formContent .Ij9SVa_inputBlock .Ij9SVa_listArea .Ij9SVa_listContainer .Ij9SVa_listItemBlockFocused .Ij9SVa_buttonsContainer .Ij9SVa_right {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.Ij9SVa_settingsPageContainer .Ij9SVa_contentBlock .Ij9SVa_mainContainer .Ij9SVa_formContent .Ij9SVa_inputBlock .Ij9SVa_listArea .Ij9SVa_listContainer .Ij9SVa_listItemBlockFocused .Ij9SVa_buttonsContainer .Ij9SVa_saveBtn {
  cursor: pointer;
  border: 1px solid #000;
  border-radius: 5px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  padding: 5px 10px;
  display: flex;
}

.Ij9SVa_settingsPageContainer .Ij9SVa_contentBlock .Ij9SVa_mainContainer .Ij9SVa_formContent .Ij9SVa_inputBlock .Ij9SVa_listArea .Ij9SVa_listContainer .Ij9SVa_listItemBlockFocused .Ij9SVa_buttonsContainer .Ij9SVa_paraphraseBtn {
  cursor: pointer;
  background-color: #e8f0fb;
  border-radius: 5px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  display: flex;
}

.Ij9SVa_settingsPageContainer .Ij9SVa_contentBlock .Ij9SVa_mainContainer .Ij9SVa_formContent .Ij9SVa_inputBlock .Ij9SVa_listArea .Ij9SVa_listContainer .Ij9SVa_listItemBlockFocused .Ij9SVa_buttonsContainer .Ij9SVa_removeBtn {
  cursor: pointer;
  text-decoration: underline;
}

.Ij9SVa_settingsPageContainer .Ij9SVa_contentBlock .Ij9SVa_mainContainer .Ij9SVa_formContent .Ij9SVa_inputBlock .Ij9SVa_listArea .Ij9SVa_listContainer .Ij9SVa_listItemBlockFocused .Ij9SVa_buttonsContainer .Ij9SVa_saveBtn:hover {
  color: #fff;
  background-color: #25262a;
}

.Ij9SVa_settingsPageContainer .Ij9SVa_contentBlock .Ij9SVa_mainContainer .Ij9SVa_formContent .Ij9SVa_inputBlock .Ij9SVa_listArea .Ij9SVa_listContainer .Ij9SVa_listItemBlockFocused .Ij9SVa_buttonsContainer .Ij9SVa_removeBtn:hover {
  color: red;
}

.Ij9SVa_settingsPageContainer .Ij9SVa_contentBlock .Ij9SVa_mainContainer .Ij9SVa_formContent .Ij9SVa_inputBlock textarea {
  box-sizing: border-box;
  color: #5a5a5d;
  resize: vertical;
  border: 1px solid #bebebf;
  border-radius: 5px;
  width: 100%;
  padding: 12px 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.Ij9SVa_settingsPageContainer .Ij9SVa_contentBlock .Ij9SVa_mainContainer .Ij9SVa_formContent .Ij9SVa_inputBlock .Ij9SVa_buttonsArea {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0 0;
  display: flex;
}

.Ij9SVa_settingsPageContainer .Ij9SVa_contentBlock .Ij9SVa_mainContainer .Ij9SVa_formContent .Ij9SVa_inputBlock .Ij9SVa_buttonsArea .Ij9SVa_underlineBtn {
  color: #25262a;
  border: 1px solid #25262a;
  border-radius: 5px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 45px;
  display: flex;
}

.Ij9SVa_settingsPageContainer .Ij9SVa_contentBlock .Ij9SVa_mainContainer .Ij9SVa_formContent .Ij9SVa_inputBlock .Ij9SVa_buttonsArea .Ij9SVa_underlineBtn i, .Ij9SVa_settingsPageContainer .Ij9SVa_contentBlock .Ij9SVa_mainContainer .Ij9SVa_formContent .Ij9SVa_inputBlock .Ij9SVa_buttonsArea .Ij9SVa_underlineBtn .Ij9SVa_icon {
  margin-right: 5px;
}

.Ij9SVa_settingsPageContainer .Ij9SVa_contentBlock .Ij9SVa_mainContainer .Ij9SVa_formContent .Ij9SVa_inputBlock .Ij9SVa_buttonsArea .Ij9SVa_underlineBtn:hover {
  cursor: pointer;
  border: 1px solid #25262a;
  border-radius: 5px;
}

.Ij9SVa_settingsPageContainer .Ij9SVa_contentBlock .Ij9SVa_mainContainer .Ij9SVa_formContent .Ij9SVa_inputBlock .Ij9SVa_buttonsArea .Ij9SVa_mainBtn {
  color: #25262a;
  border-radius: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 45px;
  text-decoration: underline;
  display: flex;
}

.Ij9SVa_settingsPageContainer .Ij9SVa_contentBlock .Ij9SVa_mainContainer .Ij9SVa_formContent .Ij9SVa_inputBlock .Ij9SVa_buttonsArea .Ij9SVa_mainBtn:hover {
  cursor: pointer;
}

.wT4FkG_mainContainer {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.wT4FkG_mainContainer .wT4FkG_container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  display: flex;
}

.wT4FkG_mainContainer .wT4FkG_container .wT4FkG_logo {
  padding-bottom: 38px;
}

.wT4FkG_mainContainer .wT4FkG_container .wT4FkG_mainBox {
  background: #fff;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 400px;
  padding: 50px 70px;
  font-family: Poppins;
  display: flex;
  box-shadow: 0 0 102.5px #0000001a;
}

.wT4FkG_mainContainer .wT4FkG_container .wT4FkG_mainBox a {
  color: #007dfa;
  text-decoration: none;
}

.wT4FkG_mainContainer .wT4FkG_container .wT4FkG_mainBox a:hover {
  color: #0064c8;
  text-decoration: underline;
}

.wT4FkG_mainContainer .wT4FkG_container .wT4FkG_mainBox .wT4FkG_title {
  color: #101012;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
}

.wT4FkG_mainContainer .wT4FkG_container .wT4FkG_mainBox form {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 25px;
  display: flex;
}

.wT4FkG_mainContainer .wT4FkG_container .wT4FkG_mainBox form .wT4FkG_inputArea {
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: 100%;
  margin-bottom: 24px;
  display: flex;
}

.wT4FkG_mainContainer .wT4FkG_container .wT4FkG_mainBox form .wT4FkG_inputArea label {
  color: #5a5a5d;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 2px;
  font-size: 16px;
  font-weight: 400;
  display: flex;
}

.wT4FkG_mainContainer .wT4FkG_container .wT4FkG_mainBox form .wT4FkG_inputArea input {
  box-sizing: border-box;
  color: #5a5a5d;
  border: 1px solid #bebebf;
  border-radius: 8px;
  width: 100%;
  padding: 12px 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.wT4FkG_mainContainer .wT4FkG_container .wT4FkG_mainBox form .wT4FkG_submitBtn {
  color: #fff;
  background-color: #007dfa;
  border: 0;
  border-radius: 8px;
  width: 100%;
  height: 52px;
  margin-top: 25px;
  padding: 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.wT4FkG_mainContainer .wT4FkG_container .wT4FkG_mainBox form .wT4FkG_submitBtn:hover {
  cursor: pointer;
  background-color: #0064c8;
}

.wT4FkG_mainContainer .wT4FkG_container .wT4FkG_mainBox form .wT4FkG_boxFooter {
  color: #bebebf;
  margin-top: 20px;
}

h2 {
  margin: 0;
  padding: 0;
}

/*# sourceMappingURL=index.f21cd287.css.map */
